import { createContext, useContext, useEffect, useState } from "react";
import * as socketIO from "socket.io-client";
import { io_path } from "../io_path";

const intialData = {
    socket: undefined,
    user: null,
    room: [],
    game: null,
};

const SocketContext = createContext(intialData);

export function useSocket() {
    return useContext(SocketContext);
}

export default function SocketProvider({ children }) {
    const [socket, setSocket] = useState(undefined);
    const [user, setUser] = useState(null);
    const [room, setRoom] = useState([]);
    const [savedPlayers, setSavedPlayers] = useState([]);
    const [game, setGame] = useState(null);
    const [winner, setWinner] = useState(null);

    // console.log(process.env.REACT_APP_SERVER_URL);
    useEffect(() => {
        if (socket) {
            socket.on(io_path.BINGO, (data) => {
                if (data?.error) return console.log(data.error);
                setGame((prev) => ({ ...prev, game_state: data.game_state }));
                // console.log(data.winner);
                const userForWin = [...room, user].find(
                    (i) => i.id === data.winner
                );
                const winner_name = userForWin ? userForWin : null;
                // console.log(data);
                // console.log(room);
                // console.log(user);
                // console.log(userForWin);
                // console.log(winner_name);
                setWinner(winner_name);
            });

            socket.on(io_path.CHANGE_WIN_NUMS, (nums_data) => {
                // setRoom((prev) => {
                //     return prev.map((i) => {
                //         if (i.id === nums_data.id) {
                //             return {
                //                 ...i,
                //                 win_nums: nums_data.nums,
                //             };
                //         }
                //         return i;
                //     });
                // });
                setSavedPlayers(nums_data);
            });

            return () => {
                socket.off(io_path.BINGO);
                socket.off(io_path.CHANGE_WIN_NUMS);
            };
        }
    }, [room, socket]);
    useEffect(() => {
        if (socket) {
            socket.on(io_path.GAME_STATE, (data) =>
                setGame((prev) => ({ ...prev, ...data }))
            );

            socket.on(io_path.USER_STATE, (data) => {
                // console.log(data);
                setRoom((prev) => {
                    // console.log(prev);
                    // console.log(data);
                    return prev.map((item) => ({
                        ...item,
                        in_game_tickets: data[item.id],
                    }));
                });
            });

            return () => {
                socket.off(io_path.GAME_STATE);
            };
        }
    }, [socket]);
    // console.log(game);
    console.log("room is");
    console.log(room);

    function changePickedNumUser(type = false) {
        let data_storage = window.localStorage.getItem("win_nums") || "0";
        data_storage = JSON.parse(data_storage);
        // console.log(data_storage);
        const doByType = type ? 1 : -1;
        data_storage += doByType;
        window.localStorage.setItem("win_nums", JSON.stringify(data_storage));
    }

    return (
        <SocketContext.Provider
            value={{
                socket,
                connectToSocket,

                socket_emit,

                user,
                room,
                game,
                savedPlayers,

                changePickedNumUser,

                winner,
            }}
        >
            {children}
        </SocketContext.Provider>
    );

    function socket_emit(emit = "", ...arg) {
        if (socket) {
            socket.emit(emit, ...arg);
        } else {
            console.log("Not connected for emmit");
        }
    }
    function connectToSocket({ token, user, game }, cb = false) {
        // console.log(process.env.REACT_APP_SERVER_URL);
        let socket = socketIO.connect(process.env.REACT_APP_SERVER_URL, {
            extraHeaders: {
                Authorization: `Bearer ${token}`,
            },
            reconnectionAttempts: 2,
        });

        socket.on("connect", () => {
            // console.log("connect!");
            setSocket(socket);
            setUser(user);
            setGame(game);
            if (cb) cb();
        });

        socket.on(io_path.ROOM_CONNECTED, (data) => {
            // console.log(data);
            if (data?.type === "users") {
                // console.log(data.data);
                // console.log(user);

                // console.log(data.data);
                // setRoom(data.data); //.filter((i) => i.id != user.id)
                setRoom((prev) => {
                    return data.data.map((item) => {
                        const a = prev.find((j) => j.id == item.id);
                        return {
                            ...a,
                            ...item,
                        };
                    });
                });
            }
            if (data?.type === "player") {
                setUser((prev) => ({ ...prev, ...data.data }));
            }
            if (data?.type === "tickets") {
                setGame((prev) => ({ ...prev, ...data.data }));
            }
            if (data?.type === "users_room") {
                // console.log(data.data);
                setRoom((prev) => {
                    return prev.map((item) => {
                        const a = data.data.find((j) => j.id == item.id);
                        return {
                            ...a,
                            ...item,
                        };
                    });
                });
            }
            // setRoom((prev) => [...prev.filter((i) => i.id !== user.id), user]);
        });

        socket.on("connect_error", (error) => {
            // console.log(error);
            alert("socket connect error");
        });

        socket.on("disconnect", (user) => {
            setSocket(false);
            socket.off("connect_error");
            socket.off(io_path.ROOM_CONNECTED);
            socket.off("connect");
        });
    }
}
