export const io_path = {
    INIT_USER: "init_user",
    BUY_TICKET: "buy_ticket",
    PICK_NUMBER: "pick_number",
    USER_STATE: "user_state",
    GAME_STATE: "game_state",

    USERS_INIT_DATA: "users_init_data",
    PREPARE_YOR_TICKET_INIT_STATE: "prepare_your_ticket_init_state",

    BINGO_NUMS: "bingo_nums",
    BINGO: "bingo",

    READY: "ready",
    START_TIMER: "start_timer",

    CHANGE_WIN_NUMS: "change_win_nums",
    TEMP_SAVE_WIN_NUMS: "temp_save_win_nums",

    ROOM_CONNECTED: "room_connected",
    ROOM_DISCONNECTED: "room_disconnected",
};
