import { useEffect, useState } from "react";

const SecondsTimer = ({ time, counterLength = 0 }) => {
    const [seconds, setSeconds] = useState(time);

    useEffect(() => {
        console.log(counterLength);
        setSeconds(time);
        const timer = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds > 0) {
                    return prevSeconds - 1;
                } else {
                    clearInterval(timer);
                    return 0;
                }
            });
        }, 1000);

        return () => clearInterval(timer); // Очистка интервала при размонтировании компонента
    }, [counterLength]);

    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
            2,
            "0"
        )}`;
    };

    return `${formatTime(seconds)}`;
};

export default SecondsTimer;
