export default function BingoNums({ nums = [] }) {
    return (
        <div className="bingo-num-head">
            <div className="holder">{renderHeadNums()}</div>
        </div>
    );
    function renderHeadNums() {
        if (nums.length === 0) return "";
        if (nums.length === 0) return <div className="num" />;

        return nums.map((num) => {
            // const letter = getLetter(num);
            return (
                <div className={`num ${getLetter(num)}`} key={num}>
                    {/* <span>{letter}</span> */}
                    <span>{num}</span>
                </div>
            );
        });
    }
    function getLetter(num) {
        if (num <= 15) return "B";
        if (num <= 30) return "I";
        if (num <= 45) return "N";
        if (num <= 60) return "G";
        return "O";
    }
}

/*
TIKETS LOGIC: 
B       I       N       G       O
(1-15)  (16-30) (31-45) (46-60) (61-75)
1       -       -       -       -
2       -       -       -       -
3       -       -       -       -
4       -       -       -       -
5       -       -       -       -
*/
