import GameLayer from "../../Game/Game";
import Prepare from "../../Game/Prepare";
import Winner from "../../Game/Winner";

export default function DesktopGame({ game }) {
    return (
        <div id="desktop">
            {game?.game_state < 2 ? <Prepare desktop /> : ""}
            {game?.game_state === 2 ? <GameLayer desktop /> : ""}
            {game?.game_state > 2 ? <Winner desktop /> : ""}
        </div>
    );
}
