import React from "react";
import ReactDOM from "react-dom/client";
import "./style/index.scss";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import SocketProvider from "./hook/SocketControl";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <SocketProvider>
            <App />
        </SocketProvider>
    </BrowserRouter>
);
