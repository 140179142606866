import { useEffect } from "react";
import { useSocket } from "../../hook/SocketControl";
import { io_path } from "../../io_path";

export default function Winner({ desktop = false }) {
    const { winner, user, room, socket, savedPlayers } = useSocket();

    useEffect(() => {
        let win_nums = window.localStorage.getItem("win_nums") || "0";
        win_nums = JSON.parse(win_nums);
        window.localStorage.removeItem("win_nums");
        // IF WE HAVE NO DATA - TRY GET FROM CAHCE FROM SERVER
        socket.emit(io_path.CHANGE_WIN_NUMS, win_nums);
    }, []);
    const sorted_data = getSortedData(winner, savedPlayers);

    if (!sorted_data) return "End cache time";
    console.log(winner);
    console.log(savedPlayers);
    // console.log(user);
    // console.log(room);

    return (
        <div className="winner">
            {desktop && <div className="bg" />}
            <div className="lg">
                <img src={"/src/winner_bingo_m.png"} alt="lg" />
            </div>
            <div className="players-list">{renderPlayers(sorted_data)}</div>
        </div>
    );
    return <h1>Winner is: {JSON.stringify(winner) || "end"}</h1>;
}

function renderPlayers(players = []) {
    return players.map((item, index) => {
        const imgs = ["win_1.png", "win_2.png", "win_3.png", "win_n.png"];
        const avtr = index < 3 ? imgs[index] : imgs[imgs.length - 1];
        return (
            <div className="player" key={index + "_" + item.id}>
                <div className="avtr">
                    <img src={`/src/winners/${avtr}`} alt={`winner_${index}`} />
                </div>
                <div className="cnt">
                    <div className="name">{item.nick}</div>
                    <div className="nums">{item.win_nums} win bumbers</div>
                </div>
            </div>
        );
    });
}
function getSortedData(winner, savedPlayers = []) {
    if (!savedPlayers) return false;
    let winnerData = winner
        ? savedPlayers.find((i) => i.id === winner.id)
        : savedPlayers.find((i) => i?.winner);
    // if(!winnerData) = winnerData ?
    if (winner && winnerData) {
        winnerData = {
            ...winner,
            ...winnerData,
        };
    }
    if (!winnerData) return false;
    const otherSortedPlayers = savedPlayers
        .filter((i) => i.id !== winnerData.id)
        .sort((a, b) => b.win_nums - a.win_nums);
    return [winnerData, ...otherSortedPlayers];
}

//winner
/*
{
    "id": 3,
    "games": 11,
    "tickets": 8,
    "in_game_tickets": 1,
    "win_games_count": 0,
    "nick": "Kryak",
    "avatar": "3.png"
}
*/
// savedPlayers
/*
[
    {
        "nick": "Sosok",
        "id": 2,
        "avatar": "2.png",
        "win_nums": 16
    },
    {
        "nick": "Aboba",
        "id": 1,
        "avatar": "1.png",
        "win_nums": 22
    },
    {
        "nick": "Kryak",
        "id": 3,
        "avatar": "3.png",
        "win_nums": 15,
        "winner": true
    }
]
*/
