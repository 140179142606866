import GameLayer from "../../Game/Game";
import Prepare from "../../Game/Prepare";
// import UsersLayer from "../../Game/Users";
import Winner from "../../Game/Winner";

export default function MobileGame({ game }) {
    return (
        <div id="mobile">
            {game?.game_state < 2 ? <Prepare /> : ""}
            {game?.game_state === 2 ? <GameLayer /> : ""}
            {game?.game_state > 2 ? <Winner /> : ""}

            {/* <UsersLayer /> */}
        </div>
    );
}
