import { ReactComponent as BingoTextSvg } from "../svg/bingo_text.svg";
export default function LoadingScreen({ mobile = false }) {
    return (
        <div className={`${mobile ? "loading-mobile" : "loading-desk"}`}>
            <div className="bg" />
            <div className="bingo">
                <BingoTextSvg />
            </div>
            <div className="loader" />
        </div>
    );
}
