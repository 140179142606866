import { useState } from "react";

export default function Ticket({
    ticket,
    tickets = [],
    pickNumber = () => {},
    counterNums = [],
    add_cls = "",
    cb = false,
}) {
    // console.log(tickets);
    const [bingo, setBingo] = useState(tickets);

    return (
        <div
            className={`ticket ${add_cls}`}
            onClick={() => {
                if (cb) {
                    cb();
                }
            }}
        >
            {/* <div className="head">
                <span>B</span>
                <span>I</span>
                <span>N</span>
                <span>G</span>
                <span>O</span>
            </div> */}
            <div className="t_num">*num</div>
            <div className="body">{renderBody()}</div>
        </div>
    );

    function renderBody() {
        // console.log(tickets);
        const bodyItems = [];
        // for (let i = 0; i < bingo.length; i++) {
        for (let i = 0; i < tickets.length; i++) {
            const line = [];
            // for (let j = 0; j < bingo.length; j++) {
            for (let j = 0; j < tickets.length; j++) {
                // const number = bingo[j][i][0];
                // const picked = bingo[j][i][1];
                const number = tickets[j][i][0];
                const picked = tickets[j][i][1];
                // if (counterNums.includes(number) && !picked)
                //     goPickNumber(number, ticket, j, i);

                line.push(
                    <button
                        key={number}
                        disabled={picked}
                        className={!number ? "none" : ""}
                        onClick={() => {
                            if (picked) return;
                            console.log(number);

                            // goPickNumber(number, ticket, j, i);
                        }}
                    >
                        <span>{number}</span>
                    </button>
                );
            }
            bodyItems.push(
                <div className="line" key={`${i}_line`}>
                    {line}
                </div>
            );
        }
        return bodyItems;
    }

    function goPickNumber(number, ticket, row, column) {
        // console.log(counterNums, number);
        // console.log(counterNums.includes(number));
        if (!counterNums.includes(number))
            return console.log("ПІШОВ НАХУЙ, ШАХРАЙ"); // VALVE CALL ME! WILL DO ANTI-CHEAT FOR YOU
        pickNumber(number, ticket, row, column);

        setBingo((prev) => {
            let newVal = [...prev];
            newVal[row][column][1] = true;
            // newVal[0][0][0][1] = true;
            return newVal;
        });
    }
}
