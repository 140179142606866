import { useEffect, useState, useRef } from "react";
import { useSocket } from "../../hook/SocketControl";
import { io_path } from "../../io_path";
import Ticket from "./Ticket";
import BingoNums from "./BingoNums";
import SecondsTimer from "./SecondsTimer";

import { ReactComponent as PlayersSvg } from "../svg/players_in_game.svg";
import { ReactComponent as GreenTicketSvg } from "../svg/green_game_ticket.svg";

// import MobileGame from "../View/Mobile";
// import { useParams } from "react-router-dom";

export default function GameLayer({ desktop = false }) {
    const [counter, setCounter] = useState([]);
    const [fullTickets, setFullTickets] = useState([]);
    const [bigTicket, setBigTicket] = useState([null]);
    const [timeForNextNum, setTimeForNextTime] = useState(5);
    const [bingoBtnStatus, setBingoBtnStatus] = useState(false);
    const [winNums, setWinNums] = useState(() => {
        return getWinNums();
    });
    const length_to_show = 5;
    const [activeSlideTicket, setActiveSlideTicket] = useState(0);

    const { socket, socket_emit, room, user, game, changePickedNumUser } =
        useSocket();

    const [pos, setPos] = useState({ elem: 0, mouse: 0, end_mouse: 0 });
    const [grab, setGrab] = useState(false);
    const scrollElem = useRef(null);

    useEffect(() => {
        if (!grab && pos.end_mouse !== 0) {
            if (Math.abs(pos.end_mouse) > 20) changeSlide(pos.end_mouse < 0);
            setPos({ elem: 0, mouse: 0, end_mouse: 0 });
        }
    }, [pos, grab]);
    useEffect(() => {
        socket.on(io_path.BINGO_NUMS, (list) => {
            if (list?.error) {
                alert(list.error);
                return setCounter([]);
            }
            setCounter(list);
        });
        socket.on(io_path.BUY_TICKET, (data) => {
            if (data?.error) return alert(data.error);

            const full =
                data.tickets?.map((i, ii) =>
                    i.map((j, jj) =>
                        j.map((k, kk) => [k, data.picked[ii][jj][kk]])
                    )
                ) || [];
            setFullTickets(full);
            setBigTicket([[full[0]], 0]);
        });

        socket_emit(io_path.PREPARE_YOR_TICKET_INIT_STATE);
        socket_emit(io_path.BINGO_NUMS);

        return () => {
            socket.off(io_path.BUY_TICKET);
            socket.off(io_path.BINGO_NUMS);
        };
    }, []);
    useEffect(() => {
        if (winNums > 0) {
            socket_emit(io_path.TEMP_SAVE_WIN_NUMS, winNums);

            return () => {
                socket.off(io_path.TEMP_SAVE_WIN_NUMS);
            };
        }
    }, [winNums]);
    useEffect(() => {
        if (counter.length > 0) {
            if (counter.length <= 75) {
                setTimeForNextTime((prev) => prev);
            }
            const num = counter[counter.length - 1];

            let saved_counter = window.localStorage.getItem("counter") || "[]";
            saved_counter = JSON.parse(saved_counter);
            if (!saved_counter.includes(num)) {
                checkActiveNums(num);
                const ifBingo = checkBingo();
                if (ifBingo) {
                    // console.log("BINGOO!!!");
                    // console.log(fullTickets)
                    setBingoBtnStatus(true);
                }
            }
        }
    }, [counter]);

    const myTickets =
        room.find((i) => user.nick === i.nick && user.id === i.id)
            ?.in_game_tickets || 0;
    const tick = bigTicket ? bigTicket[0] : [];
    console.log(bigTicket);
    if (desktop) {
        return (
            <div className="game">
                <div className="left">
                    <div className="head">
                        {/* <div className="head-con"> */}
                        <div className="timer">
                            <span>
                                <SecondsTimer
                                    time={timeForNextNum}
                                    counterLength={counter.length}
                                />
                            </span>
                        </div>
                        <div className="block">
                            <span>Players</span>
                            <span>
                                <PlayersSvg />
                                {room.length}
                            </span>
                        </div>

                        <div className="block">
                            <span>Tickets</span>
                            <span>
                                <GreenTicketSvg /> {fullTickets.length}
                            </span>
                        </div>
                        {/* </div> */}
                    </div>

                    <div className="bl">
                        <span>My tickets</span>
                        <span>{myTickets}</span>
                    </div>
                    <div className="bl">
                        <span>Win numbers</span>
                        <span>{winNums}</span>
                    </div>
                </div>
                <div className="center">
                    <div className="counter-slot">
                        <BingoNums nums={counter} />
                    </div>
                    <div className="bordered-area">
                        <div className="big-ticket">{renderTickets(tick)}</div>
                    </div>
                    <button
                        onClick={() => {
                            socket_emit(io_path.BINGO);
                        }}
                        className={`bingo_btn ${
                            bingoBtnStatus ? "active" : ""
                        }`}
                    >
                        <span>Bingo</span>
                    </button>
                </div>

                <div className="right">
                    <div className="tickets-container">
                        <div className="tickets">
                            <div className="holder">
                                {renderTickets(fullTickets, bigTicket[1], true)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    // mobile return
    return (
        <div className="game">
            <div className="head">
                <div className="head-con">
                    <div className="block">
                        <span>
                            <PlayersSvg /> Players
                        </span>
                        <span>{room.length}</span>
                    </div>
                    <div className="timer">
                        <span>
                            <SecondsTimer
                                time={timeForNextNum}
                                counterLength={counter.length}
                            />
                        </span>
                    </div>
                    <div className="block">
                        <span>
                            <GreenTicketSvg /> Tickets
                        </span>
                        <span>{fullTickets.length}</span>
                    </div>
                </div>
            </div>
            <div className="counter-slot">
                <BingoNums nums={counter} />
            </div>
            <div className="tickets-container">
                <div className="bordered-area">
                    <div className="titles">
                        <div className="bl">
                            <span>My tickets</span>
                            <span>{myTickets}</span>
                        </div>
                        <div className="bl">
                            <span>Win numbers</span>
                            <span>{winNums}</span>
                        </div>
                    </div>
                    <div className="big-ticket">{renderTickets(tick)}</div>
                </div>
                <div
                    className="tickets"
                    ref={scrollElem}
                    onTouchStart={(e) => {
                        const firstTouch = getTouches(e);

                        setPos((prev) => ({
                            ...prev,
                            elem: scrollElem.current,
                            mouse: firstTouch.clientX,
                        }));
                        setGrab(true);
                    }}
                    onTouchMove={(e) => {
                        if (grab) {
                            const firstTouch = getTouches(e);
                            const goPos = firstTouch.clientX - pos.mouse;
                            // alert(firstTouch.clientX);
                            // alert(pos.mouse);
                            // alert(goPos);
                            // scrollElem.current.scrollLeft = pos.elem - goPos;
                            setPos((prev) => ({ ...prev, end_mouse: goPos }));
                        }
                    }}
                    onTouchEnd={() => {
                        setGrab(false);
                    }}
                >
                    <div className="holder">
                        {renderTickets(
                            fullTickets,
                            bigTicket[1],
                            activeSlideTicket
                        )}
                    </div>
                    {slideBar(activeSlideTicket, fullTickets)}
                </div>
            </div>
            <div className="end">
                <div className="end-container">
                    <button
                        onClick={() => {
                            // if (bingoBtnStatus)
                            socket_emit(io_path.BINGO);
                        }}
                        className={bingoBtnStatus ? "active" : ""}
                    >
                        <span>Bingo</span>
                    </button>
                </div>
            </div>
        </div>
    );

    function getTouches(evt) {
        const obj =
            evt.touches || // browser API
            evt.originalEvent.touches; // jQuery
        return (
            obj[0] || {
                clientX: 0,
            }
        );
    }

    function changeSlide(direct = true, slideLength = fullTickets.length) {
        const sections = Math.ceil(slideLength / length_to_show);

        if (direct) {
            //next slide
            const newSlide =
                activeSlideTicket + 1 >= sections ? 0 : activeSlideTicket + 1;
            setActiveSlideTicket(newSlide);
        } else {
            //prev slide
            const newSlide =
                activeSlideTicket - 1 < 0
                    ? sections - 1
                    : activeSlideTicket - 1;
            setActiveSlideTicket(newSlide);
        }
    }

    function slideBar(activeSlide, slides) {
        const slideLength = slides.length;
        const sections = Math.ceil(slideLength / length_to_show);
        if (sections <= 1) return;

        const rendArr = [];
        for (let i = 0; i < sections; i++) {
            const cls = i === activeSlide ? "actv" : "";
            rendArr.push(
                <div
                    className={`section-slide ${cls}`}
                    key={i + "section_slide"}
                />
            );
        }
        return <div className="nav-tickets">{rendArr}</div>;
    }
    function renderTickets(tickets = [], state = false, activeSlide = false) {
        if (!tickets) return "";

        let forRend = tickets;

        if (activeSlide === true) {
            // just for skip and rend all
        } else if (activeSlide !== false) {
            const start = activeSlide * length_to_show;
            const end = start + length_to_show;

            forRend = forRend.slice(start, end);
        }

        console.log("activeSlide: " + activeSlide);
        return forRend.map((item, index) => {
            // alert(item[0][0][0]);
            const for_key = item ? item[0][0][0] : "-";
            const key = "ticket" + index + "_" + for_key + "_" + forRend.length;
            let ad_cls_check =
                activeSlide === true
                    ? state === index
                    : state === index + activeSlide * length_to_show;
            return (
                <Ticket
                    tickets={item}
                    ticket={index}
                    pickNumber={pickNumber}
                    counterNums={counter}
                    key={key}
                    add_cls={
                        state !== false ? (ad_cls_check ? "active" : "") : ""
                    }
                    cb={
                        state !== false
                            ? () => {
                                  if (activeSlide === true) {
                                      selectBigTicket(index, item);
                                  } else {
                                      selectBigTicket(
                                          index + activeSlide * length_to_show,
                                          item
                                      );
                                  }
                              }
                            : false
                    }
                />
            );
        });
    }
    function getWinNums() {
        let data_storage = window.localStorage.getItem("win_nums") || "0";
        data_storage = JSON.parse(data_storage);
        return data_storage;
    }
    function selectBigTicket(index, ticket) {
        setBigTicket([[ticket], index]);
    }

    function pickNumber(number, ticket, row, column) {
        socket_emit(io_path.PICK_NUMBER, { number, ticket, row, column });
        // setFullTickets((prev) => {
        //     let newVal = [...prev];
        //     newVal[ticket][row][column][1] = true;
        //     // newVal[0][0][0][1] = true;
        //     return newVal;
        // });
        // number go to server for save
    }

    function checkActiveNums(num) {
        for (
            let ticketIndex = 0;
            ticketIndex < fullTickets.length;
            ticketIndex++
        ) {
            const ticket = fullTickets[ticketIndex];

            for (let rowIndex = 0; rowIndex < ticket.length; rowIndex++) {
                const row = ticket[rowIndex];

                for (let colIndex = 0; colIndex < row.length; colIndex++) {
                    const col = row[colIndex][0];

                    if (col === num) {
                        pickNumber(num, ticketIndex, rowIndex, colIndex);
                        setFullTickets((prev) => {
                            let newOne = prev;
                            newOne[ticketIndex][rowIndex][colIndex][1] = true;

                            setBigTicket((pr) => {
                                const newTicket = newOne[pr[1]];

                                return [[newTicket], pr[1]];
                            });
                            //when reload send multi ti\mes
                            changePickedNumUser(true);
                            setWinNums((prev) => ++prev);
                            return newOne;
                        });
                        window.localStorage.setItem(
                            "counter",
                            JSON.stringify(counter)
                        );
                    }
                }
            }
        }
    }

    function checkBingo() {
        for (let i = 0; i < fullTickets.length; i++) {
            const ticket = fullTickets[i];
            const numRows = ticket.length;
            const numCols = ticket[0].length;

            // Функция для проверки ряда
            const checkRow = (row) => {
                return row.every((cell) => cell[1] === true);
            };

            // Функция для проверки столбца
            const checkColumn = (colIndex) => {
                for (let i = 0; i < numRows; i++) {
                    if (ticket[i][colIndex][1] !== true) {
                        return false;
                    }
                }
                return true;
            };

            // Проверка каждого ряда
            for (let i = 0; i < numRows; i++) {
                if (checkRow(ticket[i])) {
                    // console.log(`Ticket #${i}`);
                    // console.log(`ряд ${i}`);
                    // console.log(ticket);
                    return true;
                }
            }

            // Проверка каждого столбца
            for (let j = 0; j < numCols; j++) {
                if (checkColumn(j)) {
                    // console.log(`Ticket #${i}`);
                    // console.log(`столбeц ${j}`);
                    // console.log(ticket);
                    return true;
                }
            }
        }

        // Если бинго не найдено
        return false;
    }
}
