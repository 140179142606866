import { useEffect, useState } from "react";
import { useSocket } from "../../hook/SocketControl";
import { io_path } from "../../io_path";
import { ReactComponent as PlayersSvg } from "../svg/players.svg";
import { ReactComponent as GoldTicketSvg } from "../svg/gold_ticket.svg";
import { ReactComponent as GreenLockTicketSvg } from "../svg/green_lock_ticket.svg";
import { ReactComponent as GreenTicketSvg } from "../svg/green_ticket.svg";

import { ReactComponent as GamepadSvg } from "../svg/gamepad.svg";
import { ReactComponent as StarSvg } from "../svg/star.svg";
import { ReactComponent as BlueTicketSvg } from "../svg/blue_ticket.svg";

export default function Prepare({ desktop = false }) {
    // const [pickCount, setPickCount] = useState("");
    const [myTickets, setMyTickets] = useState(0);
    const [buyLayout, setBuyLayout] = useState(false);
    const [usersLayout, setUsersLayout] = useState(false);

    const { socket, socket_emit, room, user, game } = useSocket();
    // console.log(user);
    // console.log(room);
    // console.log(game);

    //WHEN DISCONNECT - REMOVE PIDORA
    // !!!!!

    useEffect(() => {
        // on buy
        socket.on(io_path.BUY_TICKET, (count) => {
            if (count?.error) return alert(count.error);
            setMyTickets(count);
            // console.log(count);
        });
        //emit io_path.BUY_TICKET (smth else) for init data (myTickets) when load
        socket_emit(io_path.PREPARE_YOR_TICKET_INIT_STATE, true);
        window.localStorage.removeItem("win_nums");
        window.localStorage.removeItem("counter");

        return () => {
            socket.off(io_path.BUY_TICKET);
        };
    }, []);
    return (
        <div className="prep">
            {buyLayout && (
                <BuyLayout
                    setBuyLayout={setBuyLayout}
                    gameInfo={game}
                    buyTicket={buyTicket}
                    desktop={desktop}
                />
            )}
            <div className="top">
                <div className="block-info">
                    <div className="block">
                        <div className="icon">
                            <GreenLockTicketSvg />
                        </div>
                        {/* <div className="text">
                            <p>Players</p>
                            <p>{room.length}</p>
                        </div> */}
                        <div className="text">
                            <p>Tickets sold</p>
                            <p>{getToatalTicketsInGame(room) || "0"}</p>
                        </div>
                    </div>

                    <div className="block">
                        <div className="text">
                            <p>Tickets left</p>
                            <p>
                                {game?.max_tickets
                                    ? game.max_tickets -
                                      getToatalTicketsInGame(room)
                                    : "-"}
                            </p>
                        </div>
                        <div className="icon">
                            <GoldTicketSvg />
                        </div>
                    </div>
                </div>
                <div className="jackpot">
                    <h2>Jackpot</h2>
                    <h1>{getJackpot()}</h1>
                </div>

                <div className="timer-holder">
                    <h1>Starts in</h1>
                    <div className="timer">
                        <TimerLeft />
                    </div>
                </div>
                {/* <div className="btns">
                    <button>Transparency Assurance</button>
                </div> */}
            </div>

            <div className="bottom">
                {desktop ? (
                    <>
                        <div className="me-info">
                            <div
                                className="logo"
                                // style={{
                                //     backgroundImage: user?.avatar
                                //         ? user.avatar
                                //         : "none",
                                // }}
                            >
                                <img
                                    src={
                                        user?.avatar
                                            ? user.avatar
                                            : "/src/avata-holder.png"
                                    }
                                    alt=""
                                    onError={({ target }) => {
                                        target.src = "/src/avata-holder.png";
                                        target.style.width = "80%";
                                        target.style.height = "80%";
                                    }}
                                />
                            </div>
                            <div className="nick">{user.nick}</div>
                            <div className="blocks">
                                <div className="block">
                                    <span>Games played</span>
                                    <span>
                                        <GamepadSvg />{" "}
                                        {user?.games ? user?.games : ""}
                                    </span>
                                </div>
                                <div className="block">
                                    <span>Games won</span>
                                    <span>
                                        <StarSvg />{" "}
                                        {user?.win_games_count
                                            ? user?.win_games_count
                                            : ""}
                                    </span>
                                </div>
                                <div className="block">
                                    <span>Tickets bought</span>
                                    <span>
                                        <BlueTicketSvg />{" "}
                                        {user?.tickets ? user?.tickets : ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="r-b">
                            <div className="players-info">
                                <div className="players-count block">
                                    <div className="icon">
                                        <PlayersSvg />
                                    </div>
                                    <div className="inf">
                                        <span>Players</span>
                                        <span>{room.length}</span>
                                    </div>
                                </div>
                                <div
                                    className="btn block"
                                    onClick={() => {
                                        setUsersLayout(true);
                                    }}
                                >
                                    SEE ALL PLAYERS
                                </div>
                            </div>

                            <div className="bb">
                                <div className="buy-block">
                                    <div className="head">
                                        {/* <span>Bought Tikets</span> */}
                                        <span>
                                            {myTickets === 0
                                                ? "no tickets bought"
                                                : `your Tickets: ${myTickets}`}
                                        </span>
                                    </div>
                                    <button
                                        onClick={() => {
                                            setBuyLayout(true);
                                        }}
                                    >
                                        Buy tickets
                                    </button>
                                </div>
                                <div className="assur">
                                    Transparency Assurance
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="players-info">
                            <div className="players-count block">
                                <div className="icon">
                                    <PlayersSvg />
                                </div>
                                <div className="inf">
                                    <span>Players</span>
                                    <span>{room.length}</span>
                                </div>
                            </div>
                            <div
                                className="btn block"
                                onClick={() => {
                                    setUsersLayout(true);
                                }}
                            >
                                SEE ALL PLAYERS
                            </div>
                        </div>

                        <div className="me-info">
                            <div
                                className="logo"
                                // style={{
                                //     backgroundImage: user?.avatar
                                //         ? user.avatar
                                //         : "none",
                                // }}
                            >
                                <img
                                    src={
                                        user?.avatar
                                            ? user.avatar
                                            : "/src/avata-holder.png"
                                    }
                                    alt=""
                                    onError={({ target }) => {
                                        target.src = "/src/avata-holder.png";
                                        target.style.width = "80%";
                                        target.style.height = "80%";
                                    }}
                                />
                            </div>
                            <div className="nick">{user.nick}</div>
                            <div className="blocks">
                                <div className="block">
                                    <span>Games played</span>
                                    <span>
                                        <GamepadSvg />
                                        {user?.games ? user?.games : ""}
                                    </span>
                                </div>
                                <div className="block">
                                    <span>Games won</span>
                                    <span>
                                        <StarSvg />
                                        {user?.win_games_count
                                            ? user?.win_games_count
                                            : ""}
                                    </span>
                                </div>
                                <div className="block">
                                    <span>Tickets bought</span>
                                    <span>
                                        <BlueTicketSvg />
                                        {user?.tickets ? user?.tickets : ""}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="bb">
                            <div
                                className={`buy-block ${
                                    myTickets === 0 ? "" : "est"
                                }`}
                            >
                                <div className="head">
                                    {/* <span>Bought Tikets</span> */}
                                    <span>
                                        {myTickets === 0 ? (
                                            "no tickets bought"
                                        ) : (
                                            <>
                                                your Tickets: {myTickets}
                                                <GreenTicketSvg />
                                            </>
                                        )}
                                    </span>
                                </div>
                                <button
                                    onClick={() => {
                                        setBuyLayout(true);
                                    }}
                                >
                                    Buy tickets
                                </button>
                            </div>
                            <div className="assur">Transparency Assurance</div>
                        </div>
                    </>
                )}
            </div>

            <div className={`users ${usersLayout ? "show-users" : ""}`}>
                <div className="container">
                    <div
                        className="close"
                        onClick={() => {
                            setUsersLayout(false);
                        }}
                    />
                    {desktop && <h2>All players</h2>}
                    <div
                        className="list new-list"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {renderNewUsersList(room)}
                    </div>
                </div>
            </div>
        </div>
    );

    function buyTicket(count) {
        socket_emit(io_path.BUY_TICKET, count);
    }
    function getToatalTicketsInGame(r = room) {
        return r?.reduce((acc, curr) => {
            return acc + (curr?.in_game_tickets || 0);
        }, 0);
    }
    function renderNewUsersList(users) {
        return users
            .filter((i) => i.id !== user.id)
            .map((i) => {
                return (
                    <div className="user" key={i.id}>
                        <div className="user-info">
                            <div className="block">
                                <div className="b-top">
                                    <div className="logo">
                                        <img
                                            src={
                                                i?.avatar
                                                    ? i.avatar
                                                    : "/src/avata-holder.png"
                                            }
                                            alt=""
                                            onError={({ target }) => {
                                                target.src =
                                                    "/src/avata-holder.png";
                                                target.style.width = "80%";
                                                target.style.height = "80%";
                                            }}
                                        />
                                    </div>
                                    <span className="title-span">{i.nick}</span>
                                </div>
                            </div>
                            <div className="block">
                                <div className="blocks">
                                    <div className="b-inf">
                                        <span>Games played</span>
                                        <span>
                                            <GamepadSvg />
                                            {i.games}
                                        </span>
                                    </div>
                                    <div className="b-inf">
                                        <span>Games won</span>
                                        <span>
                                            <StarSvg />
                                            {i?.win_games_count || 0}
                                        </span>
                                    </div>
                                    <div className="b-inf">
                                        <span>Tickets bought</span>
                                        <span>
                                            <BlueTicketSvg />
                                            {i.tickets}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="in-game-tickets">
                            <span>Tickets in the game:</span>
                            <span>
                                {typeof i.in_game_tickets === "number"
                                    ? i.in_game_tickets
                                    : "-"}
                            </span>
                        </div>
                    </div>
                );
            });
    }

    function getJackpot() {
        const tickets_sold = getToatalTicketsInGame();
        const { ticket_cost = 1, commission = 0 } = game;
        const prcnt = commission / 100;
        const sum = tickets_sold * ticket_cost;
        return (sum - sum * prcnt).toFixed(2);
    }

    function renderUsersList(users) {
        return users
            .filter((i) => i.id !== user.id)
            .map((i) => {
                return (
                    <div className="user" key={i.id}>
                        {i.nick}
                        {/* {i?.games && <div>{i.games}</div>}
                        {i?.tickets && <div>{i.tickets}</div>} */}
                    </div>
                );
            });
    }
}

//Buy layout
function BuyLayout({ setBuyLayout, gameInfo, buyTicket, desktop = false }) {
    const [amount, setAmount] = useState("");
    const { ticket_cost, tickets_left } = gameInfo;
    const price = ticket_cost
        ? amount
            ? +amount * ticket_cost
            : ticket_cost
        : "";
    return (
        <div className="buy-tickets">
            <div
                className="close-lar"
                onClick={() => {
                    setBuyLayout(false);
                }}
            />
            <div className="buy-container">
                <div className="titles">
                    <h1>BUY TIKETS</h1>
                </div>
                <div
                    className="close"
                    onClick={() => {
                        setBuyLayout(false);
                    }}
                />
                <div className="inputs">
                    <label htmlFor="amount" className="labl">
                        Amount of tikets
                    </label>
                    <input
                        type="text"
                        id="amount"
                        className="inp"
                        value={amount}
                        placeholder="1"
                        onChange={({ target }) => {
                            setAmount(target.value);
                        }}
                    />
                </div>
                <div className="inputs">
                    <div className="labl">Tiket price</div>
                    <div className="inp">{ticket_cost || "loading"} PLRM</div>
                </div>
                <div className="inputs">
                    <div className="labl">Total price</div>
                    <div className="inp">{price} PLRM</div>
                </div>
                <div className="hint">
                    Max tickets allowed{" "}
                    {typeof tickets_left === "number" ? tickets_left : "alot"}
                </div>
                <button onClick={goBuy}>Buy tickets</button>
            </div>
        </div>
    );
    function goBuy() {
        if (!ticket_cost) return;
        buyTicket(+amount ? +amount : 1);
        setBuyLayout(false);
    }
}
// READY OR NOT
function PrepareBtn({ myTickets }) {
    const [prep, setPrep] = useState(false);
    const [loading, setLoading] = useState(false);
    const { socket, socket_emit } = useSocket();

    useEffect(() => {
        socket.on(io_path.READY, (st, type = "me") => {
            if (type !== "me") return;
            // console.log(st);
            setLoading(false);
            setPrep(st);
        });
        socket_emit(io_path.READY, null); // for init state

        return () => {
            socket.off(io_path.READY);
        };
    }, []);
    return <button onClick={goPrep}>Ready ({prep ? "yes" : "no"})</button>;

    function goPrep() {
        if (myTickets === 0) {
            let conf = window.confirm("У вас нет билетов, Продолжить?");
            if (!conf) return;
        }
        if (loading) return;
        setLoading(true);
        let newPrep = !prep;
        socket_emit(io_path.READY, newPrep);
        // setPrep(newPrep);
    }
}

//TIMER
function TimerLeft() {
    const { socket, socket_emit } = useSocket();

    const [timer, setTimer] = useState(0);
    useEffect(() => {
        socket_emit(io_path.START_TIMER);

        socket.on(io_path.START_TIMER, (timeLeft) => {
            // console.log(timeLeft);
            setTimer(timeLeft);
        });
        return () => {
            socket.off(io_path.START_TIMER);
        };
    }, []);

    if (!timer) return "";
    return (
        <div className="timer">
            <TimerCouner time={timer} />
        </div>
    );
}

const TimerCouner = ({ time }) => {
    const [remainingTime, setRemainingTime] = useState(time);

    useEffect(() => {
        setRemainingTime(time);
    }, [time]);
    useEffect(() => {
        let intervalId;

        if (remainingTime > 0) {
            intervalId = setInterval(() => {
                setRemainingTime((prevTime) => prevTime - 10);
            }, 10);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const formatTime = (time) => {
        if (!time || time < 1) return `0:0:0`;
        const minutes = Math.floor(time / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        const milliseconds = Math.floor((time % 1000) / 10);

        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
            2,
            "0"
        )}`;
        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
            2,
            "0"
        )}:${String(milliseconds).padStart(2, "0")}`;
    };

    return <div>{formatTime(remainingTime)}</div>;
};
