import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import Loading from "../Layouts/Loading";
import ErrorWindow from "../Layouts/ErrorWindow";
import { useSocket } from "../../hook/SocketControl";
// import Prepare from "./Prepare";
// import UsersLayer from "./Users";
// import GameLayer from "./Game";
// import Winner from "./Winner";
import MobileGame from "../View/Mobile";
import DesktopGame from "../View/Desktop";

export default function Game() {
    const [statusInit, setStatusInit] = useState({
        loading: true,
        error: false,
        error_msg: "",
    });

    const { game: gameID } = useParams();
    const [searchParams] = useSearchParams();
    const game_token = searchParams.get("token");
    const mobile = searchParams.get("mobile");
    // console.log(mobile);

    const { connectToSocket, socket, game } = useSocket();

    useEffect(() => {
        if (!game_token || !gameID) {
            setStatusInit({
                loading: false,
                error: true,
                error_msg: "You have no auth data",
            });
        } else {
            init_game_user(gameID, game_token);
        }
    }, []);

    if (statusInit.loading) return <Loading mobile={mobile} />;
    if (statusInit.error) return <ErrorWindow msg={statusInit.error_msg} />;

    // console.log(room);
    // console.log(room.length);
    // console.log(game);
    // return <Loading mobile />;

    if (mobile !== null) {
        return <MobileGame game={game} />;
    }
    return <DesktopGame game={game} />;
    // return (
    //     <>
    //         <h1>Game id: {gameID} </h1>
    //         <h2>Вы подключенны: {!!socket ? "YES" : "NO"}</h2>
    //         {/* Need to do "head" with users status  and state */}
    //         <UsersLayer />
    //         {game?.game_state < 2 ? <Prepare /> : ""}
    //         {game?.game_state === 2 ? <GameLayer /> : ""}
    //         {game?.game_state > 2 ? <Winner /> : ""}
    //     </>
    // );

    async function init_game_user(game, token) {
        // TO DO : send with cookie settings ( to check other time session)
        try {
            const { data } = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/api/game/enter",
                {
                    game_id: game,
                    user_token: token,
                }
            );
            // console.log(data);
            connectToSocket(data, () => {
                setStatusInit({
                    loading: false,
                    error: false,
                    error_msg: "",
                });
            });
        } catch (err) {
            console.log(err);
            setStatusInit({
                loading: false,
                error: true,
                error_msg: err.response.data.msg,
            });
        }
    }
}
