import { Route, Routes } from "react-router-dom";
import Game from "./components/Game";

function App() {
    return (
        <Routes>
            <Route path="/:game" element={<Game />} />
            <Route path="*" element={<h1>Not auth for game</h1>} />
        </Routes>
    );
}

export default App;
